import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { arrayOf, bool, number, object } from 'prop-types';
import { AiFillPushpin, AiOutlineArrowDown } from 'react-icons/ai';
import { Box, Flex, Text, IconButton, Center, Divider } from '@chakra-ui/react';

dayjs.extend(relativeTime);
import { styWishlishWrapper } from './styles';

function WishlistItem({ item, index }) {
  return (
    <Box key={`wishlist-${index}`}>
      <Flex alignItems="top" marginTop="16px" boxShadow="sm">
        <Box width="100%">
          <Box padding="4px 8px" bgColor="bgPrimary" width="100%" color="mainColorText">
            <Flex alignItems="center">
              <Text marginRight="8px" fontWeight="bold" fontSize="sm">
                {item.name}
              </Text>
              {item.isPinByAdmin === 1 && <AiFillPushpin size={12} />}
            </Flex>
          </Box>
          <Divider height="1px" bgColor="bgAlternative" />
          <Box padding="4px 8px" bgColor="bgPrimary" width="100%">
            <Text fontSize="sm" color="mainColorText">
              {item.wish}
            </Text>
          </Box>
        </Box>
      </Flex>
      <Text
        fontSize="xs"
        color="bgPrimary"
        fontFamily="bodyAlternative"
        marginTop="4px"
        marginLeft="4px"
      >
        {dayjs(item.time).fromNow()}
      </Text>
    </Box>
  );
}

WishlistItem.propTypes = {
  item: object.isRequired,
  index: number.isRequired,
};

/**
 * @function WishlistCard
 * @description a functional component for wishlist card
 */
function WishlistCard({ wishlistData, loading }) {
  const renderWishlist = () => {
    let finalResult = [];
    let wishlist = [];
    let pinnedWishlist = [];

    wishlistData.forEach((item, index) => {
      if (item.isPinByAdmin === 1) {
        pinnedWishlist.push(<WishlistItem key={index} item={item} index={index} />);
      } else {
        wishlist.push(<WishlistItem key={index} item={item} index={index} />);
      }
    });

    wishlist = wishlist.reverse();
    finalResult = [...pinnedWishlist, wishlist];

    return finalResult;
  };

  const getHeight = React.useMemo(() => {
    if (wishlistData.length === 0) return '0';
    if (wishlistData.length === 1) return '200px';
    return '300px';
  }, [wishlistData.length]);

  return (
    <Box css={styWishlishWrapper} id="wishlist__box">
      <Box height={getHeight} overflow="scroll" margin="16px 0">
        <Box paddingBottom="36px">{!loading && renderWishlist()}</Box>
      </Box>
      {wishlistData.length > 2 && (
        <Box width="100%" marginTop="-32px">
          <Center>
            <IconButton
              boxShadow="xl"
              size="sm"
              icon={<AiOutlineArrowDown />}
              isRound
              className="animation-heart button__arrowdown"
            />
          </Center>
        </Box>
      )}
    </Box>
  );
}

WishlistCard.propTypes = {
  loading: bool,
  wishlistData: arrayOf(object),
};

WishlistCard.defaultProps = {
  loading: false,
  wishlistData: [],
};

export default WishlistCard;
